import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppState } from '../../../app.service';
import { AppVars } from '../../../app.vars';
import { AppointmentDetails, AppointmentEvent } from './appointment-event';
import { getHtmlContent, getHtmlSubject } from './notification-content-extractor';

interface MailMessage {
  from: string;
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  content: string;
  replyTo?: string;
}

interface Payload {
  message: MailMessage;
  orgCode?: string;
}

// Named Cloud function
const SEND_EMAIL = 'sendEmail';
@Injectable({
  providedIn: 'root',
})
export class AppointmentNotificationService {
  apptNotification$: BehaviorSubject<{ appointment: any; event: AppointmentEvent; blockedNotification: boolean }> = new BehaviorSubject(
    null
  );

  constructor(private ngFn: AngularFireFunctions, private _as: AppState) {
    this.apptNotification$
      .pipe(
        switchMap((data) => {
          if (data && data.appointment) {
            console.log('Appointment details: ', data);
            // Check if appointment type has blocked notifications before sending email
            const notificationConfig = this._as.get(AppVars.ORG_METADATA)['notification-config'];
            const blockedNotification = !notificationConfig?.sendMail || notificationConfig?.blockAppointmentNotification === true;

            // Send email
            const msg = this.getNotificationMsg(data.appointment, data.event, data.blockedNotification);
            // console.log('Mail message details: ', msg, data.appointment);
            return blockedNotification ? of({ status: false, error: 'Appointment notification is blocked!' }) : this.sendNotification(msg);
          } else {
            return of({ status: false, error: 'No appointment data available to notify!' });
          }
        })
      )
      .subscribe((result) => {
        if (result && result.status) {
          console.log('AppointmentNotificationService  sendNotification(): result', result);
        } else {
          console.log('ERROR! [AppointmentNotificationService  sendNotification():', result?.error);
        }
      });
  }

  private getNotificationMsg(appointment: any, event: AppointmentEvent, blockedNotification = false): MailMessage {
    //Load email template based on event and replace place holders
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const notificationConfig = this._as.get(AppVars.ORG_METADATA)['notification-config'];
    const apptNotification = this.getAppointmentEventInfo(appointment, blockedNotification);
    const content = getHtmlContent(apptNotification, event);
    const subject = getHtmlSubject(apptNotification, event);
    // Add CC if configured
    const cc = notificationConfig.copyTo || '';
    const to = apptNotification.email || cc;
    const from = 'notifications@accidentmate.com';
    const replyTo = `"${user.displayName || user.email}" <${user.email}>`;
    const mailMessage = { content, to, cc, subject, from, replyTo };
    console.log('Mail message details: ', mailMessage);
    return mailMessage;
  }

  private getAppointmentEventInfo(appointment: any, blockedNotification = false): AppointmentDetails {
    const apptDetails = {
      with: appointment.with || '',
      start: appointment.start || '',
      name: appointment.name || '',
      details: appointment.details || '',
      notes: appointment.notes || '',
      email: blockedNotification ? null : appointment.email || '',
      orgName: this._as.get(AppVars.ORG_NAME),
      facility: appointment.facility,
      facilityUrl: appointment.facilityUrl || encodeURI(`https://www.google.com/maps/search/${appointment.facilityAddress}`),
      facilityAddress: appointment.facilityAddress,
      facilityPhone: appointment.facilityPhone,
    };
    return apptDetails;
  }

  private sendNotification(message: MailMessage): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const payload: Payload = { message, orgCode };
    const sendEmail = this.ngFn.httpsCallable(SEND_EMAIL);
    return sendEmail(payload);
  }
}
