import { AppointmentDetails, AppointmentEvent } from './appointment-event';
import { getMailSubject, getMailTemplate } from './notification-templates';

export function getHtmlContent(appointment: AppointmentDetails, event: AppointmentEvent): string {
  const html = { content: '' };
  html.content = getMailTemplate(event);
  return replacePlaceHolders(html.content, appointment);
}

export function getHtmlSubject(appointment: AppointmentDetails, event: AppointmentEvent): string {
  const html = { content: '' };
  html.content = getMailSubject(event);
  return replacePlaceHolders(html.content, appointment);
}

function replacePlaceHolders(content: string, appointment: AppointmentDetails): string {
  console.log('NCE replacePlaceHolders() apptDetails:', appointment);
  const timezone = 'America/Los_Angeles';
  const aDateTime = getLocalDateTime(new Date(appointment.start), timezone);
  const faclityName = appointment.facility ? ` - ${appointment.facility}` : '';
  return content
    .replace(/{{heading}}/g, 'Appointment Schedule')
    .replace(/{{orgName}}/g, appointment.orgName || ' - ')
    .replace(/{{appointment.with}}/g, appointment.with || ' - ')
    .replace(/{{facility}}/g, faclityName)
    .replace(/{{facilityAddress}}/g, appointment.facilityAddress || ' - ')
    .replace(
      /{{facilityUrl}}/g,
      `${appointment.facilityUrl ? '<a href="' + appointment.facilityUrl + '" target="_blank">Open Map</a>' : ''}`
    )
    .replace(/{{facilityPhone}}/g, getFormattedPhone(appointment.facilityPhone) || ' - ')
    .replace(/{{appointment.notes}}/g, appointment.notes || ' - ')
    .replace(/{{patientName}}/g, appointment.name || ' - ')
    .replace(/{{appointment.date}}/g, aDateTime.date)
    .replace(/{{appointment.time}}/g, aDateTime.time);
}

export function getLocalDateTime(dateValue: Date, tz: string): { date: string; time: string } {
  return {
    date: dateValue.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: tz }),
    time: dateValue.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: tz }),
  };
}

function getFormattedPhone(phone: string): string {
  if (!phone) {
    return '';
  }
  // Remove non-numeric characters from the phone number
  const numericOnly = phone.replace(/\D/g, '');

  // Use regex to format the phone number
  const formattedNumber = numericOnly.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

  return formattedNumber;
}
